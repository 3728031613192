import styles from './styles/StnVideoAd.module.scss';
import AdPlaceholder, { AdPlaceholderProps } from './AdPlaceholder';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { STN_ENABLED } from 'constants.js.erb';
import cn from 'classnames';
import useScreenSize from 'hooks/useScreenSize';
import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';

export default function StnVideoAd({ placement, className }: { placement: 'TOP' | 'INLINE'; className?: string }) {
  const [{ disableVideoAd }] = useApplicationContainer();
  const { isSmallScreen } = useScreenSize();

  // for now small screen is always at the top of the page
  if (disableVideoAd || (isSmallScreen && placement === 'INLINE')) return null;

  return (
    <div className={cn(styles.Container, placement === 'TOP' && styles[placement], className)}>
      {placement === 'TOP' ? <InTreePlacement /> : <OutOfTreePlacement />}
    </div>
  );
}

function InTreePlacement() {
  return (
    <div className={styles.Spacer}>
      <StnAdPlaceholder className={styles.Ad} />
    </div>
  );
}

// due to `container-type: inline-size;` we need to mount the ad outside the dom tree and
// position it absolutely where the spacer div is to get pinning to the bottom to work
function OutOfTreePlacement() {
  const el = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ top: number; left: number; width: number; height: number }>();

  useEffect(() => {
    function handleResize() {
      const rect = el.current?.getBoundingClientRect();
      if (!rect) return;

      setPosition({
        top: window.scrollY + rect.top,
        left: window.scrollX + rect.left,
        width: rect.width,
        height: rect.height
      });
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className={styles.Spacer} ref={el} />
      {position &&
        createPortal(
          <StnAdPlaceholder
            style={{
              position: 'absolute',
              ...position
            }}
          />,
          document.body
        )}
    </>
  );
}

function StnAdPlaceholder(props: AdPlaceholderProps) {
  return (
    <AdPlaceholder {...props} id="stn-player" placeholder={!STN_ENABLED}>
      StnVideoAd
    </AdPlaceholder>
  );
}
